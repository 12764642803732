<template>
  <div id="skrillPay">
    <div class="content_box">
      <div class="inner_max">
        <div class="form_deposit">
          <h4>{{ $t('deposit.skrill.header') }}</h4>
          <div class="content">
            <div class="info_box">
              <img src="@/assets/images/channel/skrill.png" alt />
              <p>{{ $t('deposit.skrill.desc') }}</p>
              <ul>
                <li>{{ $t('deposit.skrill.inst1') }}</li>
                <li>{{ $t('deposit.skrill.inst2') }}</li>
                <li>{{ $t('deposit.skrill.inst3') }}</li>
                <li>{{ $t('deposit.skrill.inst4') }}</li>
              </ul>
            </div>
            <div class="form_main">
              <p class="title">{{ $t('deposit.skrill.form.header') }}</p>
              <el-form label-position="top" :model="skillForm" ref="skillForm" :rules="skilRules" status-icon>
                <div class="form_box">
                  <ul class="clearfix">
                    <li class="fl">
                      <AccountNumber
                        :supportedCurrenciesList="validCurrencies"
                        @setCurrency="setCurrency"
                        @setAccountNumber="setAccountNumber"
                      ></AccountNumber>
                    </li>
                    <li class="fr">
                      <el-form-item :label="$t('common.field.amt')" prop="amount">
                        <numeric-input
                          v-model="skillForm.amount"
                          :currency="accountCurrency"
                          :precision="2"
                        ></numeric-input>
                      </el-form-item>
                    </li>
                  </ul>
                  <ul class="clearfix">
                    <li class="fl">
                      <el-form-item :label="$t('deposit.skrill.form.email')" prop="email">
                        <el-input v-model="skillForm.email" data-testid="email"></el-input>
                      </el-form-item>
                    </li>
                    <li class="fr">
                      <el-form-item :label="$t('common.field.imptNotes')">
                        <el-input v-model="skillForm.notes" data-testid="applicationNotes"></el-input>
                      </el-form-item>
                    </li>
                  </ul>
                </div>
                <div class="info">
                  <ul v-html="$t('deposit.skrill.reminder', { platform: $platform.info.fullName })"></ul>
                </div>
                <el-button type="primary" :loading="loading" @click="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import { apiSkrill_payment } from '@/resource';
import mixin from '@/mixins/page/deposit';

export default {
  name: 'SkrillPay',
  components: { NumericInput, AccountNumber },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit })));
      } else if (parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else {
        callback();
      }
    };
    return {
      skillForm: {
        accountNumber: '',
        amount: '',
        email: '',
        notes: ''
      },
      skilRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  computed: {
    validCurrencies() {
      return ['USD', 'GBP', 'CAD', 'EUR'];
    }
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.skillForm.accountNumber = accountNumber;
    },
    submitForm(e) {
      this.$refs['skillForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.data && result.data.code == 0) {
                const msg = result.data.msg;
                if (msg) window.location = `https://pay.skrill.com/?sid=${msg}`;
                else {
                  this.loading = false;
                  this.errorMessage(this.$t('deposit.default.failed'));
                }
              } else {
                this.loading = false;
                this.errorMessage(this.$t('deposit.default.failed'));
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      return apiSkrill_payment(
        {
          mt4Account: this.skillForm.accountNumber,
          operateAmount: this.skillForm.amount,
          applicationNotes: this.skillForm.notes,
          skrillEmail: this.skillForm.email,
          paymentChannel: '1'
        },
        this.token
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
